/**
 * Wrapper service over src/environments files
 * Angular substitutes the correct environment file at build time
 * This service is used for type safe retrieval of the env vars
 * Env vars can be accessed through signals for a reactive approach or traditional getter methods
 */
import { computed, Injectable, signal } from '@angular/core';
import { environment } from '../../../environments/environment';

interface AppEnvironment {
  // pg app config
  name: string;
  production: boolean;
  DOMAIN_WILDCARD: string;
  API_URL: string;
  // SCIOPS_URL: string;
  SCIOPS_API: string;
  plugins: any[];

  // 3rd party services
  AMPLITUDE_ID: string;
  AMPLITUDE_URL: string;
  SSO_URL_CLEVER: string;
  SSO_CLEVER_ENABLED: boolean;
  SSO_URL_CLASS_LINK: string;
  SSO_URL_GOOGLE: string;
  GOOGLE_CLIENT_ID: string;
  GOOGLE_TAG_ID: string;
}

@Injectable({ providedIn: 'root' })
export class AppEnvironmentService {
  private _environment = signal<AppEnvironment>(environment);

  public readonly ssoUrlGoogle = computed<string>(() => this._environment().SSO_URL_GOOGLE);
  public readonly ssoUrlClever = computed<string>(() => this._environment().SSO_URL_CLEVER);
  public readonly ssoUrlClassLink = computed<string>(() => this._environment().SSO_URL_CLASS_LINK);

  public readonly googleClientId = computed<string>(() => this._environment().GOOGLE_CLIENT_ID);

  // TODO - add the getters
}
